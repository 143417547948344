import axiosInstance from "auth/FetchInterceptor";
import { DANHMUC_API } from "constants/ApiConstant";
const DanhMucService = {};

//#region Dmkk
DanhMucService.getDmLoaiKk = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmloaikk`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getDmKk = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmkk`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getAllDmKk = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/get-all-dmkk`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.addDmkk = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/add-dmkk`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.updateDmkk = function (data) {
  return axiosInstance.put(`${DANHMUC_API}/update-dmkk`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.deleteDmkk = function (data) {
  return axiosInstance.delete(`${DANHMUC_API}/delete-dmkk?id=${data}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
//#endregion

//#region DmLoaiDv
DanhMucService.getDmLoaiDv = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmloaidichvu`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getAllDmLoaiDv = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-all-dmloaidichvu`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.addDmLoaiDv = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/add-dmloaidichvu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.updateDmLoaiDv = function (data) {
  return axiosInstance.put(`${DANHMUC_API}/update-dmloaidichvu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.deleteDmLoaiDv = function (data) {
  return axiosInstance.delete(`${DANHMUC_API}/delete-dmloaidichvu?id=${data}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
//#endregion

//#region Dmdonvitinh
DanhMucService.getDmdonvitinh = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmdonvitinh`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getAllDmdonvitinh = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/get-all-dmdonvitinh`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.addDmdonvitinh = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/add-dmdonvitinh`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.updateDmdonvitinh = function (data) {
  return axiosInstance.put(`${DANHMUC_API}/update-dmdonvitinh`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.deleteDmdonvitinh = function (data) {
  return axiosInstance.delete(`${DANHMUC_API}/delete-dmdonvitinh?id=${data}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
//#endregion

//#region Dmhoatchat
DanhMucService.getDmhoatchat = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmhoatchat`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getAllDmhoatchat = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/get-all-dmhoatchat`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.addDmhoatchat = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/add-dmhoatchat`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.updateDmhoatchat = function (data) {
  return axiosInstance.put(`${DANHMUC_API}/update-dmhoatchat`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.deleteDmhoatchat = function (data) {
  return axiosInstance.delete(`${DANHMUC_API}/delete-dmhoatchat?id=${data}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
//#endregion

//#region Dmnhomthuoc
DanhMucService.getDmnhomthuoc = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmnhomthuoc`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getAllDmnhomthuoc = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-all-dmnhomthuoc`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.addDmnhomthuoc = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/add-dmnhomthuoc`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.updateDmnhomthuoc = function (data) {
  return axiosInstance.put(`${DANHMUC_API}/update-dmnhomthuoc`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.deleteDmnhomthuoc = function (data) {
  return axiosInstance.delete(`${DANHMUC_API}/delete-dmnhomthuoc?id=${data}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
//#endregion

//#region Dmthuocvattu
DanhMucService.getDmThuocVatTu = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmthuocvattu`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getAllDmThuocVatTu = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-all-dmThuocVatTu`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.searchDmThuocVatTu = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/search-dmthuocvattu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getHangSapHetTon = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-hangsaphetton`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.addDmThuocVatTu = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/add-dmThuocVatTu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.updateDmThuocVatTu = function (data) {
  return axiosInstance.put(`${DANHMUC_API}/update-dmThuocVatTu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.deleteDmThuocVatTu = function (data) {
  return axiosInstance.delete(`${DANHMUC_API}/delete-dmThuocVatTu?id=${data}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
//#endregion

DanhMucService.getDmLoaiPhieuNhapXuat = function (id) {
  return axiosInstance.get(
    `${DANHMUC_API}/get-dmloaiphieunhapxuat?maloai=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

DanhMucService.getDmDoiTuong = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmdoituong`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

DanhMucService.getDmLyDoTc = function (loai) {
  return axiosInstance.get(`${DANHMUC_API}/get-dmlydothuchi?loai=${loai}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

DanhMucService.getDmNhanVien = function (loai) {
  return axiosInstance.get(`${DANHMUC_API}/get-dmnhanvien?ct=${loai}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
DanhMucService.getDmKhachHang = function (loai) {
  return axiosInstance.get(`${DANHMUC_API}/get-dmkhachhang?ct=${loai}`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

DanhMucService.getDmHinhThucTT = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmhinhthuctt`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

DanhMucService.getDmPhai = function () {
  return axiosInstance.get(`${DANHMUC_API}/get-dmphai`, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

DanhMucService.updateHangSapHetTon = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/update-hangsaphetton`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
export default DanhMucService;
