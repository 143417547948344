/* eslint-disable jsx-a11y/iframe-has-title */
import { Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CreatePrinter } from "store/slices/sale/thuchiSlice";

const DocumentViewer = ({
  openModel,
  setIsPrinter,
  soPhieu,
  idPhieu,
  param,
  setPrintData,
  loaiPhieu,
  tenMauIn,
  tieuDe,
}) => {
  const dispatch = useDispatch();
  const [linkPrint, SetLinkPrint] = useState("");

  useEffect(() => {
    if (idPhieu === null || idPhieu === 0) return;
    const payload = {
      loaiPhieu: loaiPhieu,
      tenMauIn: tenMauIn,
      soPhieu: soPhieu,
      idPhieu: (idPhieu || 0).toString(),
      param: JSON.stringify(param),
      onSuccess: (r) => {
        if (r.data.isOk) {
          SetLinkPrint(r.data.description);
        } else {
          notification.error({
            message: "Lỗi in",
            description: r.data.description,
          });
        }
      },
    };
    if (!openModel) return;
    dispatch(CreatePrinter(payload));
  }, [idPhieu, param, tenMauIn, loaiPhieu, soPhieu, openModel, dispatch]);

  return (
    <Modal
      open={openModel}
      title={`${tieuDe}`}
      bodyStyle={{ overflowY: "hidden", maxHeight: "calc(100vh - 300px)" }}
      width={1368}
      footer={false}
      onCancel={() => {
        setIsPrinter(!openModel);
        setPrintData({ mathuchi: null, soPhieu: "" });
      }}
    >
      <iframe
        src={linkPrint}
        width={1320}
        height={700}
        style={{ border: "none" }}
      />
    </Modal>
  );
};

export default DocumentViewer;
